<template>
	<div class="chat-container">
		<div class="chat-flex-container">
			<div class="chat-history-container app-scroll-custom" ref="chat-history-container">
				<b-spinner v-if="!mensagens" id="loading-spinner" small />
				<div v-for="mensagem in mensagens"
					 v-if="!mensagem.Privado || !ocultarMensagensInternas"
					 :key="mensagem.Id"
					 :class="`mensagem ${mensagem.Origem}${mensagem.Midias && mensagem.Midias.length == 1 ? ` ${mensagem.Midias[0].Tipo ?? 'file'}` : ''}${(dataUltimaLeitura && mensagem.Data > dataUltimaLeitura && mensagem.Origem != 'log' && !mensagem.MinhaMensagem) ? ' nova-mensagem' : ''}${mensagemEncaminhamento ? ' encaminhando-mensagem' : ''}`">
					<b-container v-if="mensagem.Origem == 'log'" class="mensagem-container" fluid>
						<b-row align-h="center">
							<b-col class="line"></b-col>
							<b-col cols="12" md="auto" class="log-message">
								<div v-html="mensagem.Mensagem"></div>
								<div class="log-date text-center">{{mensagem.Data.format("DD/MM/YYYY HH:mm")}}</div>
							</b-col>
							<b-col class="line"></b-col>
						</b-row>
					</b-container>
					<div v-else
						 :class="`mensagem-container${mensagem.Privado ? ' in-private' : ''}${mensagem.Status ? ` ${mensagem.Status}` : ''}${mensagem.TipoCanal == 'email' ? ' email-container' : ''}${mensagemEncaminhamento && mensagemEncaminhamento.Data >= mensagem.Data ? ' encaminhar-email' : ''}`">
						<div class="mensagem-header text-nowrap text-truncate">
							<i v-if="mensagem.TipoCanal == 'email'"
							   :class="`fas fa-angle-${mensagem.isBodyVisible ? 'right' : 'down'} fa-fw email-expand-icon`"
							   :title="mensagem.isBodyVisible ? 'Ocultar' : 'Exibir'"
							   @click="mensagem.isBodyVisible = !mensagem.isBodyVisible"></i>
							<b>
								{{mensagem.Origem == 'cliente' ? mensagem.Cliente ?? `Cliente` : mensagem.Atendente}}
								<span v-if="mensagem.Privado">(interna)</span>
							</b>
							| {{mensagem.Data.format("DD/MM/YYYY HH:mm")}}
							<span v-if="mensagem.Assunto?.trim()" :title="mensagem.Assunto">| Assunto: {{mensagem.Assunto}}</span>
						</div>
						<div v-if="mensagem.TipoCanal != 'email' && mensagem.Midias && mensagem.Midias.length == 1 && mensagem.Midias[0].Url">
							<div v-if="mensagem.Midias[0].Tipo == 'image'"
								 class="mensagem-imagem"
								 :style="{'background-image':`url(${mensagem.Midias[0].Url})`}"
								 :title="mensagem.Midias[0].Nome"
								 @click="fullscreenImage = mensagem.Midias[0]"></div>
							<div v-else-if="mensagem.Midias[0].Tipo == 'sticker'"
								 class="mensagem-sticker"
								 :style="{'background-image':`url(${mensagem.Midias[0].Url})`}" />
							<div v-else-if="mensagem.Midias[0].Tipo == 'audio'">
								<audio :src="mensagem.Midias[0].Url" controls preload="auto" />
							</div>
							<div v-else-if="mensagem.Midias[0].Tipo == 'video'">
								<video :src="mensagem.Midias[0].Url" controls preload="auto" />
							</div>
							<a v-else
							   :href="mensagem.Midias[0].Url"
							   :download="mensagem.Midias[0].Nome"
							   target="_blank"
							   class="file-anchor">
								<b-container class="file-container" :title="mensagem.Midias[0].Nome">
									<b-row>
										<b-col cols="12" md="auto" class="pr-0">
											<i class="fas fa-arrow-alt-circle-down"></i>
										</b-col>
										<b-col class="text-truncate">
											{{mensagem.Midias[0].Nome}}
										</b-col>
									</b-row>
								</b-container>
							</a>
						</div>
						<div v-if="mensagem.TipoCanal == 'email' && mensagem.isBodyVisible" class="email-body app-scroll-custom">
							<i v-if="editor && (!mensagemEncaminhamento || mensagemEncaminhamento != mensagem)"
							   class="fas fa-share float-right mr-2 cursor-pointer"
							   title="Encaminhar..."
							   @click="mensagemEncaminhamento = mensagem"></i>
							<i v-else-if="mensagemEncaminhamento == mensagem"
							   class="fas fa-times float-right mr-2 cursor-pointer"
							   title="Cancelar encaminhamento"
							   @click="mensagemEncaminhamento = null"></i>
							<div class="email-remetente">De: {{mensagem.Remetente}}</div>
							<div v-html="mensagem.Mensagem"></div>
						</div>
						<span v-else-if="mensagem.TipoCanal != 'email' &&  mensagem.Mensagem?.trim()" v-html="mensagem.Mensagem"></span>
						<div v-if="mensagem.TipoCanal == 'email' && mensagem.Midias && mensagem.Midias.length > 0 && mensagem.isBodyVisible"
							 class="email-anexos">
							<a v-for="anexo in mensagem.Midias"
							   :href="anexo.Url"
							   target="_blank"
							   :title="anexo.Nome"
							   class="email-anexo text-truncate">{{anexo.Nome}}</a>
						</div>
						<i v-if="mensagem.Status && (mensagem.Origem == 'atendente' || mensagem.Status == 'deleted')"
						   :class="`status-icon ${mensagem.StatusIcon} float-right ml-2 mt-2`"></i>
					</div>
					<span v-if="mensagem.ErrorCode" class="status-error-message" :title="mensagem.ErrorMessage">
						A mensagem não pôde ser enviada <b>(Erro {{mensagem.ErrorCode}})</b>.
					</span>
					<span v-if="mensagem.Status == 'deleted'" class="status-error-message">
						A mensagem foi apagada pelo remetente.
					</span>
				</div>
				<div v-if="fullscreenImage" id="imagem-fullscreen">
					<div id="imagem-fullscreen-actions">
						<a :href="fullscreenImage.Url" target="_blank" :download="fullscreenImage.Nome">
							<i class="fas fa-arrow-alt-circle-down mr-3" title="Fazer download"></i>
						</a>
						<i class="fas fa-times-circle" @click="fullscreenImage = null"></i>
					</div>
					<div id="imagem-fullscreen-container"
						 :style="{ 'background-image': `url(${fullscreenImage.Url})` }"></div>
				</div>
			</div>
			<div v-if="editor" :class="`chat-input-container${loaders.enviandoMensagem ? ' sending-message' : ''}`">
				<div v-if="editor.Arquivos && editor.Arquivos.length > 0"
					 class="chat-input-files">
					<div v-for="(arquivo, index) in editor.Arquivos"
						 :title="arquivo.nome"
						 class="input-file float-left">
						<div class="input-file-name text-truncate">{{arquivo.nome}}</div>
						<i title="Remover arquivo"
						   @click="editor.Arquivos.splice(index, 1)"
						   class="fas fa-times ml-3"></i>
					</div>
				</div>
				<b-container v-if="tipoCanal == 'E-mail' && !editor.Privado" class="chat-input-info-email" fluid>
					<b-row>
						<b-col class="info-email-field mr-2">
							<label for="info-email-to">Para:</label>
							<input v-if="!mensagemEncaminhamento"
								   type="text"
								   id="info-email-to"
								   v-model="editor.Para"
								   readonly
								   :disabled="loaders.enviandoMensagem" />
							<input v-else
								   type="text"
								   id="info-email-to"
								   v-model="editor.EncaminharPara"
								   :disabled="loaders.enviandoMensagem" />
						</b-col>
						<b-col class="info-email-field">
							<label for="info-email-cc">Cc:</label>
							<input type="text" id="info-email-cc" v-model="editor.Cc" :disabled="loaders.enviandoMensagem" />
						</b-col>
					</b-row>
					<b-row>
						<b-col class="info-email-field">
							<label for="info-email-subject">Assunto:</label>
							<input type="text" id="info-email-subject" v-model="editor.Assunto" :disabled="loaders.enviandoMensagem" />
						</b-col>
					</b-row>
				</b-container>
				<div :class="`chat-input${editor.Privado ? ' in-private' : ''}`">
					<div class="chat-input-controls">
						<label v-if="editor.Arquivos"
							   for="chat-input-attach"
							   :title="`Adicionar${editor.Arquivos && editor.Arquivos.length > 0 ? ' mais' : ''} anexos`"
							   :class="`fas fa-paperclip${editor.Arquivos && editor.Arquivos.length > 0 ? ' active' : ''}`">
							<input id="chat-input-attach"
								   ref="editor-attach"
								   type="file"
								   multiple
								   :disabled="loaders.enviandoMensagem"
								   @change="anexarArquivos" />
						</label>
						<i v-if="!mensagemEncaminhamento"
						   :title="`A mensagem ficará ${editor.Privado ? 'oculta' : 'visível'} para o cliente`"
						   @click="editor.Privado = !editor.Privado"
						   :class="`fas ${editor.Privado ? 'fa-eye-slash active' : 'fa-eye'}`"></i>
					</div>
					<HtmlEditor ref="editor"
								:tools="editorToolsSet[tipoCanal] ?? ''"
								:editable="!loaders.enviandoMensagem"
								placeholder="Digite uma mensagem..."
								v-model="editor.Mensagem"
								@keydown.ctrl.enter.prevent="identificarTemplateMensagem($event)"
								@keydown.enter.exact.prevent="identificarTemplateMensagem($event) > 0 || enviarMensagem()"
								:class="`chat-editor ${editorToolsSet[tipoCanal] ? 'has-toolbar' : ''}`" />
					<b-container :class="`chat-input-submit${(textoDaMensagemDoEditor.trim() || (editor.Arquivos && editor.Arquivos.length > 0) || mensagemEncaminhamento) && (!mensagemEncaminhamento || editor.EncaminharPara?.trim()) ? '' : ' disabled'}`">
						<b-row class="h-100" align-v="center">
							<b-col>
								<b-spinner v-if="loaders.enviandoMensagem" class="text-cliente"></b-spinner>
								<i v-else
								   @click="enviarMensagem()"
								   class="fas fa-location-arrow"></i>
							</b-col>
						</b-row>
					</b-container>
				</div>
			</div>
		</div>
		<b-modal v-if="templates && templates.length > 0"
				 :static="true"
				 id="selecionar-template"
				 title="Templates disponíveis"
				 hide-footer
				 @hide="modalData['selecionar-template'].search = null; modalData['selecionar-template'].templatesMensagem = null">
			<b-container class="p-0">
				<b-row align-h="center">
					<b-col>
						<input type="text"
							   ref="selecao-template-search"
							   placeholder="Buscar template..."
							   v-model="modalData['selecionar-template'].search"
							   class="w-100" />
					</b-col>
					<b-col cols="12" md="auto" class="pl-0 py-1">
						<i v-if="!modalData['selecionar-template'].search"
						   @click="$refs['selecao-template-search'].focus()"
						   class="fas fa-search fa-fw cursor-pointer"></i>
						<i v-else
						   @click="modalData['selecionar-template'].search = null"
						   class="fas fa-times fa-fw cursor-pointer"></i>
					</b-col>
				</b-row>
			</b-container>
			<hr />
			<div id="template-mensagens" class="app-scroll-custom">
				<div v-for="template in (modalData['selecionar-template'].templatesMensagem ?? templates).filter(item => !modalData['selecionar-template'].search || (['', item.Titulo, item.Template, item.Hashtags, ''].join(' ').toLowerCase().includes(modalData['selecionar-template'].search.toLowerCase()))).sort((a, b) => a.Titulo > b.Titulo ? 0 : -1)"
					 @click="selecionarTemplateMensagem(template)"
					 class="template-mensagem-item mb-3">
					<b>{{template.Titulo}}</b>
					<br />
					<span v-if="template.Hashtags"
						  v-for="hashtag in template.Hashtags?.split(' ')"
						  class="text-primary mr-2">
						{{hashtag}}
					</span>
					<div v-html="template.TemplateDoCliente" class="template-mensagem-template"></div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
	import moment from "moment";
	import axios from "axios";
	import { mapGetters } from "vuex";

	import { Mensagem } from "@/assets/js/models/atendimento.js";
	import ChatHub from "@/assets/js/hubs/ChatHub";

	import HtmlEditor from "@/components/inputs/HtmlEditor";

	export default {
		name: "Chat",
		components: {
			HtmlEditor
		},
		props: {
			atendimentoId: {
				type: String,
				default: null
			},
			tipoCanal: {
				type: String,
				default: null
			},
			ocultarMensagensInternas: {
				type: Boolean,
				default: false
			},
			scrollAutomatico: {
				type: Boolean,
				default: true
			},
			notificacaoLeitura: {
				type: Boolean,
				default: false
			},
			editor: {
				type: Object,
				default: null
			},
			templates: {
				type: Array,
				default: null
			}
		},
		data() {
			return {
				mensagens: null,
				dataUltimaLeitura: null,
				fullscreenImage: null,
				chatHubConnection: null,
				mensagemEncaminhamento: null,
				loaders: {
					enviandoMensagem: false,
				},
				modalData: {
					"selecionar-template": {
						search: null,
						templatesMensagem: null
					}
				},
				editorToolsSet: {
					"E-mail": "B|I|U|S|A|IMG",
					"WhatsApp": "B|I|U|S"
				}
			};
		},
		computed: {
			...mapGetters({
				dadosUsuarioAutenticado: "authentication/authDadosUsuario",
			}),
			meuId() {
				return this.dadosUsuarioAutenticado.usuarioId;
			},
			textoDaMensagemDoEditor() {
				let span = document.createElement("span");
				span.innerHTML = this.editor.Mensagem;
				return span.textContent ?? span.innerText ?? "";
			}
		},
		watch: {
			mensagemEncaminhamento() {
				this.atualizarInfoEmail();
			}
		},
		methods: {
			iniciarChat() {
				this.mensagens = null;
				return Mensagem.getChat(this.atendimentoId).then(chat => {
					this.mensagens = chat.Mensagens;
					if (this.tipoCanal == "E-mail") this.atualizarInfoEmail();
					if (this.tipoCanal == "Telefonia") this.editor.Privado = true;
					[...this.mensagens.filter(mensagem => mensagem.Origem == "cliente").slice(-1), ...this.mensagens.filter(mensagem => mensagem.Origem == "atendente").slice(-1)].forEach(mensagem => {
						if (mensagem.TipoCanal == "email") mensagem.isBodyVisible = true;
					});
					this.dataUltimaLeitura = chat.DataUltimaLeitura;
					if (this.notificacaoLeitura) this.notificarLeitura();
				});
			},
			notificarLeitura() {
				return axios.put(`/api/atendimento/${this.atendimentoId}/li-agora`);
			},
			scrollToEnd(behavior = "smooth") {
				if (this.$refs["chat-history-container"]) this.$refs["chat-history-container"].scrollTo({ top: this.$refs["chat-history-container"].scrollHeight, behavior: behavior });
			},
			scrollToNewMessages(behavior = "smooth") {
				if (!document.querySelector(".mensagem.nova-mensagem")) {
					this.scrollToEnd(behavior);
					return;
				}
				if (this.$refs["chat-history-container"]) this.$refs["chat-history-container"].scrollTo({ top: document.querySelector(".mensagem.nova-mensagem").offsetTop - 133, behavior: behavior });
			},
			atualizarInfoEmail() {
				if (!this.editor) return;
				if (this.mensagemEncaminhamento) {
					let assunto = this.mensagemEncaminhamento.Assunto?.trim() ?? "Sem assunto";
					this.editor.Assunto = `ENC: ${assunto}`;
					return;
				}
				let ultimoEmailCliente = this.mensagens.filter(mensagem => mensagem.Origem == "cliente" && mensagem.TipoCanal == "email").slice(-1)[0];
				if (ultimoEmailCliente) {
					let assunto = ultimoEmailCliente.Assunto?.trim() ?? "Sem assunto";
					this.editor.Assunto = `${assunto.trim().startsWith("RE: ") ? '' : "RE: "}${assunto}`;
				}
			},
			receberNovasMensagens() {
				let ultimaMensagem = this.mensagens.slice(-1)[0];
				return axios.get(`/api/atendimento/${this.atendimentoId}/novas-mensagens`, {
					params: {
						dataInicial: ultimaMensagem ? ultimaMensagem.Data.format("YYYY-MM-DD HH:mm:ss") : null
					}
				}).then(response => {
					if (!response.data || response.data.length == 0 || !this) return;
					let novasMensagens = response.data.filter(mensagem => !this.mensagens.find(item => item.Id == mensagem.Id));
					if (novasMensagens.length == 0) return;
					this.mensagens.push(...novasMensagens.map(mensagem => {
						let novaMensagem = new Mensagem(mensagem);
						if (novaMensagem.TipoCanal == "email") novaMensagem.isBodyVisible = true;
						return novaMensagem;
					}));
					if (this.tipoCanal == "E-mail") this.atualizarInfoEmail();
					if (this.notificacaoLeitura) this.notificarLeitura().then(response => {
						if (response && response.data) this.dataUltimaLeitura = moment(response.data);
					});
					this.$nextTick(() => {
						this.scrollToEnd();
					});
				});
			},
			anexarArquivos() {
				Array.from(this.$refs["editor-attach"].files).forEach(file => {
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						let arquivo = {
							nome: file.name,
							tipo: file.type,
							url: reader.result
						};
						if (!this.editor.Arquivos.find(a => a.url == arquivo.url)) this.editor.Arquivos.push(arquivo);
					}
				});
				this.$refs["editor-attach"].value = "";
			},
			async criarAnexo(arquivo) {
				let form = new FormData(),
					content = await fetch(arquivo.url).then(content => content.blob()).then(blob => blob);
				form.append("arquivo", content, arquivo.nome);
				return await axios.post(`api/atendimento/${this.atendimentoId}/criar-anexo`, form, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}).then(response => response.data).catch(() => {
					this.$bvToast.toast(`Houve um erro ao tentar salvar o anexo "${arquivo.nome}".`, {
						title: "Ops, algo deu errado...",
						toaster: "b-toaster-top-right",
						variant: "danger",
						solid: true,
						autoHideDelay: 5000
					});
				});
			},
			async enviarMensagem() {
				// Impede de enviar mensagem sem conteúdo nenhum ou que seja um encaminhamento sem endereço do destinatário;
				if ((!this.textoDaMensagemDoEditor.trim() && (!this.editor.Arquivos || this.editor.Arquivos.length == 0) && !this.mensagemEncaminhamento) || (this.mensagemEncaminhamento && !this.editor.EncaminharPara?.trim())) return;

				this.loaders.enviandoMensagem = true;
				if (this.tipoCanal == "E-mail" && !this.editor.Privado) {
					let form = new FormData();
					if (this.mensagemEncaminhamento) {
						form.append("AtendimentoMensagemId", this.mensagemEncaminhamento.Id);
						form.append("Destinatarios", this.editor.EncaminharPara);
					}
					if (this.editor.Cc) form.append("CC", this.editor.Cc);
					if (this.editor.Assunto) form.append("Assunto", this.editor.Assunto);
					if (this.editor.Mensagem) form.append("Mensagem", this.editor.Mensagem);
					if (this.editor.Arquivos && this.editor.Arquivos.length > 0) await Promise.all(this.editor.Arquivos.map(async arquivo => {
						let content = await fetch(arquivo.url).then(content => content.blob()).then(blob => blob);
						form.append("Anexos", content, arquivo.nome);
						return;
					}));

					return await axios.post(`api/atendimentoemail/${this.atendimentoId}/${this.mensagemEncaminhamento ? "encaminhar" : "enviar"}-email`, form, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}).then(() => {
						this.loaders.enviandoMensagem = false;
						if (this.mensagemEncaminhamento) {
							this.mensagemEncaminhamento = null;
							this.editor.EncaminharPara = null;
							this.atualizarInfoEmail();
						}
						this.editor.Cc = null;
						this.editor.Arquivos = [];
						this.editor.Mensagem = "";
						this.$refs["editor"].focus();
					}).catch(() => {
						this.loaders.enviandoMensagem = false;
						this.$bvToast.toast(`Houve um erro na tentativa de ${this.mensagemEncaminhamento ? "encaminhar" : "enviar"} o e-mail. Tente novamente mais tarde.`, {
							title: "Ops, algo deu errado...",
							toaster: "b-toaster-top-right",
							variant: "danger",
							solid: true,
							autoHideDelay: 5000
						});
					});
					return;
				}
				if (this.editor.Arquivos && this.editor.Arquivos.length > 0) {
					for (let item in this.editor.Arquivos) {
						let arquivo = this.editor.Arquivos[item];
						await this.criarAnexo(arquivo).then(response => {
							return this.chatHubConnection.sendMessage({
								AtendimentoId: this.atendimentoId,
								UsuarioId: this.meuId,
								Publico: !this.editor.Privado,
								Mensagem: item == 0 ? this.editor.Mensagem?.trim() : null,
								TipoMensagem: response.TipoArquivo,
								MidiaId: response.MidiaId
							}).catch(error => {
								this.$bvToast.toast([this.$createElement("div", { domProps: { innerHTML: `Houve um erro ao tentar enviar a mensagem.<br /><br /><b>Detalhes:</b> ${error}` } })], {
									title: "Ops, algo deu errado...",
									toaster: "b-toaster-top-right",
									variant: "danger",
									solid: true,
									autoHideDelay: 5000
								});
							});
						});
					}
					this.loaders.enviandoMensagem = false;
					this.editor.Arquivos = [];
					this.editor.Mensagem = "";
					this.$refs["editor"].focus();
					return;
				}
				await this.chatHubConnection.sendMessage({
					AtendimentoId: this.atendimentoId,
					UsuarioId: this.meuId,
					Publico: !this.editor.Privado,
					Mensagem: this.editor.Mensagem?.trim()
				}).then(() => {
					this.editor.Mensagem = "";
				}).catch(error => {
					this.$bvToast.toast([this.$createElement("div", { domProps: { innerHTML: `Houve um erro ao tentar enviar a mensagem.<br /><br /><b>Detalhes:</b> ${error}` } })], {
						title: "Ops, algo deu errado...",
						toaster: "b-toaster-top-right",
						variant: "danger",
						solid: true,
						autoHideDelay: 5000
					});
				}).finally(() => {
					this.loaders.enviandoMensagem = false;
					this.$refs["editor"].focus();
				});
				return;
			},
			identificarTemplateMensagem(event) {
				if (!this.templates || this.templates.length == 0) return 0;
				if (event.ctrlKey || event.type == "click") {
					this.$bvModal.show("selecionar-template");
					return 0;
				}
				let hashtags = this.editor.Mensagem.match(/#\w+/g);
				if (!hashtags) return 0;
				hashtags = hashtags.map(item => item.trim().toLowerCase());
				let templates = this.templates.filter(item => {
					if (!item.Hashtags?.trim()) return;
					let hashtagsDoTemplate = item.Hashtags?.split(" ").map(hashtag => hashtag.trim().toLowerCase()).filter(hashtag => hashtags.includes(hashtag));
					if (hashtagsDoTemplate.length > 0) return item;
				});
				if (templates.length > 1) {
					event.preventDefault();
					this.modalData["selecionar-template"].templatesMensagem = templates;
					this.$bvModal.show("selecionar-template");
				}
				if (templates.length == 1) {
					event.preventDefault();
					this.editor.Mensagem = templates[0].TemplateDoCliente;
					// Necessário porque o `HtmlEditor` ignora alterações externas se ele está com edição ativa.
					this.$refs["editor"].updateHtml(templates[0].TemplateDoCliente);
				}
				return templates.length;
			},
			selecionarTemplateMensagem(template) {
				this.editor.Mensagem = template.TemplateDoCliente;
				this.$bvModal.hide("selecionar-template");
			},
		},
		mounted() {
			this.iniciarChat().then(() => {
				if (this.scrollAutomatico) this.scrollToNewMessages("instant");
				ChatHub.connect().then(connection => {
					this.chatHubConnection = connection;
					this.chatHubConnection.subscribe("NovaMensagem", atendimentoId => {
						if (atendimentoId == this.atendimentoId) this.receberNovasMensagens();
					});
					this.chatHubConnection.subscribe("MensagemStatus", (mensagemId, status, details) => {
						let mensagem = this.mensagens.find(mensagem => mensagem.Id == mensagemId);
						if (mensagem) mensagem.Status = ["sent", "delivered", "read", "clock", "error", "deleted"][status];
						if (mensagem && mensagem.Status == "error" && details) {
							mensagem.ErrorCode = details.errorCode;
							mensagem.ErrorMessage = details.errorMessage;
						}
						this.$nextTick(() => {
							this.scrollToEnd();
						});
					});
					this.chatHubConnection.startInstantChat(this.atendimentoId);
				});
			});
		},
		destroyed() {
			if (!this.chatHubConnection) return;
			this.chatHubConnection.leaveInstantChat(this.atendimentoId);
			this.chatHubConnection.disconnect();
		}
	}
</script>

<style scoped>
	.chat-flex-container {
		display: flex;
		height: 100%;
		flex-direction: column;
	}

	.chat-history-container {
		flex-grow: 1;
		padding: 0 15px 15px 0;
		margin-bottom: 5px;
	}

	.mensagem {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 5px 0;
		animation: fade-in-out .5s;
	}

		.mensagem.cliente {
			align-items: start;
		}

		.mensagem.atendente {
			align-items: end;
		}

		.mensagem.cliente > .mensagem-container {
			background-color: var(--cinza-3);
		}

		.mensagem.atendente > .mensagem-container {
			background-color: var(--cor-primaria-cliente);
			color: var(--cinza-3);
		}

			.mensagem.atendente > .mensagem-container.email-container .email-anexos .email-anexo {
				color: #fff;
			}

			.mensagem.atendente > .mensagem-container.email-container .email-body {
				color: var(--cinza-5);
			}

		.mensagem.cliente + .mensagem.cliente > .mensagem-container,
		.mensagem.atendente + .mensagem.atendente > .mensagem-container {
			margin-top: 0;
		}

		.mensagem.log {
			margin: 10px 0;
			text-align: center;
		}

			.mensagem.log > .mensagem-container {
				border-radius: 0;
				max-width: 100%;
				padding: 0;
				padding-right: 15px;
			}

				.mensagem.log > .mensagem-container .log-message {
					max-width: 80%;
				}

				.mensagem.log > .mensagem-container .line {
					background-color: var(--cinza-4);
					height: 2px;
					margin-top: 9px;
				}

		.mensagem.encaminhando-mensagem .mensagem-container.email-container:not(.encaminhar-email) {
			opacity: .2;
			filter: grayscale(1);
		}

		.mensagem.encaminhando-mensagem .mensagem-container.email-container.encaminhar-email:not(.clock) {
			background-color: #eddf1d;
			color: var(--cor-primaria-cliente);
		}

			.mensagem.encaminhando-mensagem .mensagem-container.email-container.encaminhar-email:not(.clock) .email-anexos .email-anexo {
				color: var(--cor-primaria-cliente);
			}

	.mensagem-container {
		display: block;
		padding: 10px;
		border-radius: 12px;
		max-width: 60%;
		margin-top: 15px;
		transition: all ease-in-out .3s;
	}

		.mensagem-container.email-container {
			width: 80%;
			max-width: 1002px;
			padding: 10px 7px;
		}

			.mensagem-container.email-container .mensagem-header {
				margin-bottom: 0;
			}

			.mensagem-container.email-container .email-expand-icon {
				font-size: 24px;
				vertical-align: middle;
				margin: 0 3px;
				cursor: pointer;
			}

			.mensagem-container.email-container .email-body {
				margin-top: 10px;
				background-color: #fff;
				padding: 10px;
				padding-left: 39px;
				border-radius: 7px;
				overflow-x: auto;
			}

				.mensagem-container.email-container .email-body .email-remetente {
					font-size: 10px;
					margin-bottom: 12px;
				}

			.mensagem-container.email-container .email-anexos {
				margin-top: 10px;
			}

				.mensagem-container.email-container .email-anexos .email-anexo {
					display: block;
					float: left;
					color: var(--cinza-5);
					font-weight: bold;
					font-size: 13px;
					text-decoration: underline;
					margin-right: 20px;
					white-space: nowrap;
					max-width: 100%;
				}

		.mensagem-container.in-private {
			background-color: #272829 !important;
			color: var(--cinza-4);
		}

		.mensagem-container.error {
			background-color: #fff !important;
			color: var(--cinza-5) !important;
			border: 1px #dc3545 solid;
		}

		.mensagem-container.deleted {
			opacity: .7;
		}

		.mensagem-container.clock {
			opacity: .5;
		}

		.mensagem-container > .mensagem-header {
			margin-bottom: 5px;
		}

		.mensagem-container + .status-error-message {
			display: table;
			width: 100%;
			font-size: 12px;
			color: #dc3545;
			padding: 5px;
		}

	.mensagem.atendente .mensagem-container + .status-error-message {
		float: right;
		text-align: right;
	}

	.mensagem.cliente .mensagem-container + .status-error-message {
		float: left;
		text-align: left;
		padding: 5px 10px;
		opacity: .7;
	}

	.mensagem.image > .mensagem-container {
		width: 60%;
		max-width: 400px;
	}

		.mensagem.image > .mensagem-container .mensagem-imagem {
			width: 100%;
			max-width: 100%;
			height: 250px;
			border-radius: 12px;
			cursor: pointer;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}

	a.file-anchor {
		text-decoration: none;
	}

		a.file-anchor .file-container {
			padding: 7px 10px;
			border-radius: 12px;
			cursor: pointer;
			text-decoration: underline;
		}

			a.file-anchor .file-container i.fas.fa-arrow-alt-circle-down {
				font-size: 24px;
			}

			a.file-anchor .file-container i.fas.fa-arrow-alt-circle-down {
				font-size: 24px;
			}

	.mensagem.cliente a.file-anchor .file-container {
		background-color: var(--cinza-4);
		color: var(--cor-primaria-cliente);
	}

	.mensagem.atendente a.file-anchor .file-container {
		background-color: rgba(255, 255, 255, .15);
		color: #fff;
	}

	.mensagem.sticker > .mensagem-container {
		background-color: transparent;
		padding: 0;
	}

		.mensagem.sticker > .mensagem-container .mensagem-sticker {
			width: 200px;
			height: 200px;
			background-position: center;
			background-size: contain;
		}

	.mensagem.sticker.atendente > .mensagem-container {
		color: var(--cinza-5);
	}

	.mensagem.audio.cliente .mensagem-container {
		padding-bottom: 0;
	}

	.mensagem.audio.cliente .mensagem-header {
		margin-bottom: 0;
	}

	.mensagem.audio.cliente audio::-webkit-media-controls-panel {
		background-color: var(--cinza-3);
		padding: 0;
	}

	.mensagem.video video {
		width: 100%;
		max-width: 500px;
		height: 250px;
	}

	.mensagem.nova-mensagem:before {
		content: "Novas mensagens";
		display: block;
		width: 100%;
		padding: 7px 30px;
		color: var(--cor-primaria-cliente);
		font-weight: bold;
		margin: 40px auto;
		text-align: center;
		border-top: 2px var(--cor-primaria-cliente) solid;
		border-bottom: 2px var(--cor-primaria-cliente) solid;
	}

	.mensagem.nova-mensagem + .mensagem.nova-mensagem:before {
		content: none;
	}

	.status-icon {
		font-size: 12px;
	}

		.status-icon.read {
			color: #32ddf8;
		}

	.log-date {
		text-align: center;
		font-size: 10px;
		line-height: 10px;
		color: var(--cinza-4);
	}

	#imagem-fullscreen {
		position: fixed;
		background-color: rgba(0, 0, 0, .7);
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 1000;
		animation: fade-in-out .7s;
	}

	#imagem-fullscreen-container {
		width: 80vw;
		height: 80vh;
		margin: 10vh auto;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	#imagem-fullscreen-actions {
		position: absolute;
		font-size: 30px;
		top: 20px;
		right: 30px;
		cursor: pointer;
	}

		#imagem-fullscreen-actions > * {
			color: #fff;
			text-decoration: none;
		}

			#imagem-fullscreen-actions > *:hover {
				color: var(--cinza-3);
			}

	#loading-spinner {
		display: block;
		margin: 10px auto;
	}

	.chat-input-container {
		transition: all ease-in-out .3s;
	}

		.chat-input-container.sending-message {
			opacity: .6;
		}

	.chat-input {
		display: table;
		width: 100%;
		position: relative;
		background-color: var(--cinza-3);
		min-height: 90px;
		transition: all ease-in-out .3s;
	}

		.chat-input.in-private {
			background-color: #272829 !important;
			color: var(--cinza-4);
		}

		.chat-input > div {
			float: left;
		}

	.chat-input-controls {
		width: 50px;
	}

		.chat-input-controls > .fas {
			position: relative;
			display: block;
			cursor: pointer;
			font-size: 16px;
			background-color: var(--cinza-4);
			margin: 7px;
			padding: 9px 0;
			text-align: center;
			color: #333;
			transition: all ease-in-out .3s;
		}

			.chat-input-controls > .fas.active {
				background-color: var(--cor-primaria-cliente) !important;
				color: var(--cinza-4);
			}

	.chat-input.in-private .chat-input-controls > .fas {
		background-color: var(--cinza-5);
		color: var(--cinza-3);
	}

	.chat-editor {
		width: calc(100% - 130px);
		padding: 0 7px;
		min-height: 76px;
		margin: 7px 0;
		position: relative;
		overflow: hidden;
	}

	.chat-input-submit {
		width: 80px;
		text-align: center;
		margin: 15px 0;
		padding: 0;
	}

		.chat-input-submit i.fas.fa-location-arrow {
			display: inline-block;
			cursor: pointer;
			background-color: var(--cor-primaria-cliente);
			color: #fff;
			border-radius: 50%;
			font-size: 24px;
			width: 60px;
			height: 60px;
			text-align: left;
			padding: 19px 15px;
			transform: rotate(45deg);
			transition: all ease-in-out .3s;
		}

		.chat-input-submit.disabled i.fas.fa-location-arrow {
			cursor: default;
			background-color: var(--cinza-4);
			opacity: .5;
		}


	.chat-input.in-private .chat-input-submit.disabled i.fas.fa-location-arrow {
		background-color: var(--cinza-5);
		color: var(--cinza-3);
	}

	.chat-input-files {
		display: table;
		margin: 0;
	}

		.chat-input-files > .input-file {
			background-color: #b8c1cd;
			color: #333;
			padding: 5px 10px;
			margin: 0 5px 5px 0;
			font-size: 12px;
			animation: fade-in-out .3s;
		}

			.chat-input-files > .input-file > .input-file-name {
				display: inline-block;
				max-width: 180px;
				vertical-align: top;
			}

			.chat-input-files > .input-file > .fas {
				cursor: pointer;
			}

	#chat-input-attach {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
	}

	#template-mensagens {
		max-height: calc(90vh - 130px);
	}

		#template-mensagens .template-mensagem-item {
			background-color: var(--cinza-1);
			padding: 8px 12px;
			border-radius: 12px;
			cursor: pointer;
			transition: all ease-in-out .3s;
		}

			#template-mensagens .template-mensagem-item:hover {
				background-color: var(--cor-primaria-cliente);
				color: #fff;
			}

		#template-mensagens .template-mensagem-template {
			font-size: 15px;
		}

	.info-email-field {
		display: flex;
		background-color: var(--cinza-3);
		margin-bottom: 5px;
		align-items: center;
		padding: 0;
	}

		.info-email-field label {
			font-weight: bold;
			margin: 0 7px;
			color: #333;
		}

		.info-email-field > input[type=text] {
			width: 100%;
			background-color: transparent;
			border: 0;
			padding: 0;
			margin: 7px 7px 7px 0;
			font-size: 13px;
		}

			.info-email-field > input[type=text]:focus {
				border: 0;
				box-shadow: none;
			}

	@keyframes fade-in-out {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
</style>

<style>
	.chat-editor.html-editor {
		display: flex;
		flex-direction: column-reverse;
	}

		.chat-editor.html-editor > div[contenteditable] {
			flex-grow: 1;
			max-height: 30vh;
			overflow-y: auto;
			scrollbar-width: thin;
			scrollbar-color: grey lightgrey;
		}

			.chat-editor.html-editor > div[contenteditable]::-webkit-scrollbar-track {
				background: lightgrey;
				border-radius: 0.15em;
			}

			.chat-editor.html-editor > div[contenteditable]::-webkit-scrollbar-thumb {
				background: grey;
				border-radius: 0.15em;
			}

			.chat-editor.html-editor > div[contenteditable]::-webkit-scrollbar {
				width: .5em;
				height: .5em;
			}

		.chat-editor.html-editor > .placeholder {
			top: 0;
		}

	.chat-editor.has-toolbar.html-editor > div[contenteditable] {
		margin-top: 6px;
	}

	.chat-editor.has-toolbar.html-editor > .placeholder {
		top: 34px;
		height: auto;
	}

	.chat-editor.has-toolbar.html-editor > .html-editor-tools {
		position: relative;
		display: block;
		background-color: transparent;
		margin-top: 0;
		box-shadow: none;
		border-bottom: 1px var(--cinza-4) solid;
		padding-top: 0;
		z-index: auto;
	}

		.chat-editor.has-toolbar.html-editor > .html-editor-tools > i {
			background-color: transparent;
			margin-right: 3px;
			cursor: pointer;
		}

			.chat-editor.has-toolbar.html-editor > .html-editor-tools > i.active {
				background-color: var(--cor-primaria-cliente);
				color: var(--cinza-1);
			}
</style>